import { IListedVehicleData, IVDVersion } from './interfaces/vehicle/vehicle';
import { ISelectOptions } from './select/CustomSelect';
import { AgreementSubtypes, CONTRACT_TYPE } from './agreement/Agreement';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import Compressor from 'compressorjs';
import createToast from './toast/CreateToast';
import { ToastBg } from './toast/Toast';
import { camelCase, upperFirst } from 'lodash';
import { uuidv7 } from 'uuidv7';
import { PostHog } from 'posthog-node';
import { parse } from 'cookie';
import { IncomingMessage } from 'http';

export const maxAge = 16;
export const vehicleMockupImg: string = '/assets/img/vehicle-img.png';
export const consignmentRenewalPeriod = 90;

export const buildVehicleName = (year: string, make: string, model: string, trim: string) => {
    return `${year} ${make} ${model} ${trim}`;
};
export const buildVehicleNameByVersion = (vdVersion: IVDVersion, short = false) => {
    return `${vdVersion?.vdModel.year} ${vdVersion?.vdMake.name} ${vdVersion?.vdModel.name} ${!short ? vdVersion?.name : ''}`;
};

export function nth(n: number) {
    return n + (['st', 'nd', 'rd'][((n + 90) % 100 - 10) % 10 - 1] || 'th');
};

export const populateSelectOptions = (data: { [key: string]: string }[], hasEmpty?: boolean): ISelectOptions[] => {
    return data.map((d: { [key: string]: string }) => ({
        value: d.id ? d.id : d.makeId ? d.name : d.year,
        label: d.name ? d.name : d.year, ...d
    }));
};

export function getWindow() {
    if (typeof window !== 'undefined') {
        return window;
    }

    return null;
};

export function getLocalStorage() {
    if (typeof localStorage !== 'undefined') {
        return localStorage;
    }

    return null;
};

export function getSessionStorage() {
    if (typeof sessionStorage !== 'undefined') {
        return sessionStorage;
    }

    return null;
};

export function getNavigator() {
    if (typeof navigator !== 'undefined') {
        return navigator;
    }

    return null;
};

export const getUniqueSessionId = () => {
    if (sessionStorage.getItem('sessionId')) {
        return sessionStorage.getItem('sessionId');
    }

    const sessionId = sessionStorage.getItem('sessionId') || 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });

    sessionStorage.setItem('sessionId', sessionId);

    return sessionId;
};

export function getContractName(subType: AgreementSubtypes, contractType = CONTRACT_TYPE.CONSIGNMENT) {
    if (contractType === CONTRACT_TYPE.CONSIGNMENT) {
        switch (subType) {
            case AgreementSubtypes.AMENDMENT:
                return 'Amendment';
            case AgreementSubtypes.ORIGINAL:
                return 'Initial consignment';
            case AgreementSubtypes.CHANGE:
                return 'Modification';
        }
    } else {
        switch (subType) {
            case AgreementSubtypes.AMENDMENT:
                return 'Amendment';
            case AgreementSubtypes.ORIGINAL:
                return 'Initial contract';
            case AgreementSubtypes.CHANGE:
                return 'Contract updates';
        }
    }
};

export function updateLocation(lat: number | undefined, lng: number | undefined, addressType: string, callback: Function) {
    if (lat && lng && window?.google?.maps) {
        geocodeByLatLng({lat: +lat, lng: +lng}).then((data: any) => {
            let streetData = data.find((d: any) => d.types.includes(addressType));

            if (!streetData) {
                streetData = data[0].types?.includes('plus_code') && data[0].types?.length === 1 ? data[1] : data[0];
            }
            streetData && callback(streetData);
        });
    }
};

export function getPhotoThumbnailPath(path: string, width?: number, height?: number) {
    if (!path) {
        return '';
    }

    return `/api/file?url=${encodeURIComponent(path)}${width ? `&width=${width}` : ''}${height ? `&height=${height}` : ''}`;
};

export function getImageFileMimeTypes() {
    return {
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg', '.jpe', '.pjpg', '.jfi', '.jif']
    };
};

export function getImageFileMimeTypesForFileTypes() {
    return 'image/jpeg,image/jpg,image/png';
};

export function getDocumentFileMimeTypes() {
    return {
        ...getImageFileMimeTypes(),
        'application/pdf': []
    };
};

export function makeFileLowQuality(file: File | Blob, callBack: (result: Blob) => void) {
    if (file.size > 5 * 1024 * 1024) {
        return new Compressor(file, {
            quality: 0.8,
            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {
                // Send the compressed image file to server with XMLHttpRequest.
                callBack?.(result);
            },
            error(err) {
                console.log(err.message);
            }
        });
    } else {
        callBack?.(file);
    }
};

export function getAddressObjFromGeocode(addressComponents: any) {
    const addressObj: any = {};

    addressComponents.forEach((addressComponent: any) => {
        if (addressComponent.types[0] == 'route') {
            console.log('route:' + addressComponent.long_name);
            addressObj.route = addressComponent.long_name;
        }

        if (addressComponent.types[0] == 'locality') {
            console.log('town:' + addressComponent.long_name);
            addressObj.town = addressComponent.long_name;
        }

        if (addressComponent.types[0] == 'country') {
            console.log('country:' + addressComponent.long_name);
            addressObj.country = addressComponent.long_name;
        }

        if (addressComponent.types[0] == 'postal_code') {
            console.log('pc:' + addressComponent.long_name);
            addressObj.postalCode = addressComponent.long_name;
        }

        if (addressComponent.types[0] == 'street_number') {
            console.log('street_number:' + addressComponent.long_name);
            addressObj.street_number = addressComponent.long_name;
        }

        if (addressComponent.types[0] == 'administrative_area_level_1') {
            console.log('province:' + addressComponent.short_name);
            addressObj.province = addressComponent.short_name;
        }
    });

    return addressObj;
};

export const copyToClipboardHandler = (text: string) => {
    navigator.clipboard.writeText(text);
    createToast({
        bg: ToastBg.Success,
        content: 'Copied to clipboard'
    });
};

export const unMaskPhoneNumber = (phone: string) => {
    return phone?.replace?.(/[^\d]/g, '') || '';
};

export const maskPhoneNumber = (phone: string) => {
    return phone?.replace?.(/^(\d{3})(\d{3})(\d{4}).*/, '($1)-$2-$3') || '';
};

export const calculateSaleDealerFee = (amount: number = 0, percentage: number = 0, minimum: number = 0) => {
    return Math.max((amount * percentage / 100), minimum);
}

export const url = (path?: string) => {
    const trimmedPath = path?.replace(/^\/+|\/+$/g, '');
    const cleanAppUrl = (process.env.NEXT_PUBLIC_FRONTEND_URL ?? '')?.replace(/^\/+|\/+$/g, '');
    return cleanAppUrl + '/' + trimmedPath;
}

export const generateVehicleJsonLdSchema = (listedVehicleData: IListedVehicleData, url: string) => {
    return {
      "@context": "https://schema.org",
      "@type": "Car",
      "name": listedVehicleData.vehicleName,
      "vehicleIdentificationNumber": (listedVehicleData as any)?.vin,
      "image": listedVehicleData.publishedVersion?.photos?.map(p => p.photoPathUrl).filter(p => !!p),
      "url": url,
      "offers": {
        "@type": "Offer",
        "availability": listedVehicleData.isSold ? "https://schema.org/SoldOut" : "https://schema.org/InStock",
        "price": listedVehicleData.publishedVersion.price,
        "priceCurrency": "CAD"
      },
      "itemCondition": "https://schema.org/UsedCondition",
      "brand": {
        "@type": "Brand",
        "name": listedVehicleData.publishedVersion.vdVersion.vdMake.name
      },
      "model": listedVehicleData.publishedVersion.vdVersion.vdModel.name,
      "vehicleConfiguration": listedVehicleData.publishedVersion.vdVersion.name,
      "vehicleModelDate": listedVehicleData.publishedVersion.vdVersion.vdModel.year,
      "mileageFromOdometer": {
        "@type": "QuantitativeValue",
        "value": listedVehicleData.publishedVersion.mileage,
        "unitCode": "SMI"
      },
      "color": listedVehicleData.publishedVersion.vdExteriorColor?.name,
      "vehicleInteriorColor": listedVehicleData.publishedVersion.vdInteriorColor?.name,
      "vehicleInteriorType": (listedVehicleData.publishedVersion as any)?.seating,
      "bodyType": listedVehicleData.publishedVersion.vdBodyType?.name,
      "driveWheelConfiguration": `https://schema.org/${upperFirst(camelCase(listedVehicleData.publishedVersion.vdDriveTrain?.categoryName))}Configuration`,
      "vehicleEngine": {
        "@type": "EngineSpecification",
        "fuelType": (listedVehicleData.publishedVersion as any)?.fuelType,
      },
      "vehicleTransmission":listedVehicleData.publishedVersion?.transmission,
      "numberOfDoors": listedVehicleData.publishedVersion.doorsCount,
      "vehicleSeatingCapacity": listedVehicleData.publishedVersion.seatsCount
    };
}

export const removeHtmlTags = (htmlString: string) => {
    return htmlString.replace(/<[^>]*>/g, '');
}

export async function getPostHogBootstrapData(posthogApiKey: string, req?: IncomingMessage) {
    let distinct_id = ''
    const phCookieName = `ph_${posthogApiKey}_posthog`;

    if (req) {
        const cookies = parse(req.headers.cookie || '');
        const phCookie = cookies[phCookieName]; 
        if (phCookie) {
          const phCookieParsed = JSON.parse(phCookie);
          distinct_id = phCookieParsed.distinct_id;
        }
        if (!distinct_id) {
          distinct_id = uuidv7();
        }
    }
  
    const client = new PostHog(posthogApiKey);
    const flags = await client.getAllFlags(distinct_id);
    const bootstrap = {
      distinctID: distinct_id,
      featureFlags: flags
    }
  
    return bootstrap
}

export function isUrlOrPath(url: string) {
    try {
        new URL(url);
        return 'url';
    } catch (error) {
        return 'path';
    }
}
